<template>
  <div class="absolute bottom-0 left-0 z-10 w-full px-4 py-5">
    <div class="relative">
      <div class="flex h-12 rounded-2xl bg-slate-800">
        <app-bar-link
          v-for="item in navLinks"
          :icon="item.icon"
          :path="item.path" />
      </div>
      <button-glossy
        :custom-icon="customIcon"
        :show-text="false"
        class="absolute bottom-6 left-1/2"
        @click="partyOpen = !partyOpen" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import ButtonGlossy from '../atoms/Button/Glossy.vue'
import {
  ChatBubbleOvalLeftEllipsisIcon,
  Cog6ToothIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import AppBarLink from '~/components/atoms/AppBarLink.vue'
import { useNavigationStore } from '~/stores/navigation'
import { storeToRefs } from 'pinia'

const navigationStore = useNavigationStore()

const { partyOpen } = storeToRefs(navigationStore)

const customIcon = computed(() => {
  if (partyOpen.value) {
    return XMarkIcon
  }
  return undefined
})

const navLinks = [
  {
    name: 'Home',
    path: '/home',
    icon: HomeIcon,
  },
  {
    name: 'Search',
    path: '/search',
    icon: MagnifyingGlassIcon,
  },
  {
    name: 'Messages',
    path: '/messages',
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: Cog6ToothIcon,
  },
]
</script>
