<template>
  <div class="relative p-4">
    <PartyHeader
      :title="'Party'"
      :subTitle="'mates in the party'"
      :members="members.value.length"
      :selected-game="selectedGame"
      :invite-code="invitationCode" />
    <PartyMembersList :members="members.value" />
    <PartyFooter
      :invite-code="invitationCode"
      :find-player-button="findPlayerButton" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePartiesStore } from '~/stores/parties'
import PartyHeader from '~/components/molecules/PartyHeader.vue'
import PartyMembersList from '~/components/organisms/PartyMembersList.vue'
import PartyFooter from '~/components/molecules/PartyFooter.vue'

const { mates, invitationCode, selectedGame } = storeToRefs(usePartiesStore())
const members = computed(() => mates)

defineProps({
  findPlayerButton: {
    type: Boolean,
    default: false,
  },
})
</script>
