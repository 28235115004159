<template>
  <div class="relative flex flex-col">
    <div class="flex justify-between">
      <PartyTitle :title="title" />
      <IconButton @click="leave">
        <ArrowRightOnRectangleIcon aria-hidden="true" class="h-6 w-6" />
      </IconButton>
    </div>
    <PartyInfo :members="members" :selectedGame="selectedGame" />
    <div class="flex justify-between">
      <PartyInviteCode :invite-code="inviteCode" class="w-2/3" />
      <div class="flex flex-1 justify-end space-x-4">
        <IconButton>
          <nuxt-icon class="text-2xl" fill name="gamepad" />
        </IconButton>
        <IconButton>
          <Cog6ToothIcon class="h-6 w-6" />
        </IconButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import PartyTitle from '~/components/atoms/PartyTitle.vue'
import PartyInfo from '~/components/atoms/PartyInfo.vue'
import IconButton from '~/components/atoms/Button/Icon.vue'
import {
  ArrowRightOnRectangleIcon,
  Cog6ToothIcon,
} from '@heroicons/vue/24/outline'
import PartyInviteCode from '~/components/atoms/PartyInviteCode.vue'

defineProps({
  title: {
    type: String,
    required: true,
  },
  subTitle: {
    type: String,
    required: true,
  },
  members: {
    type: Number,
    required: true,
  },
  selectedGame: {
    type: String,
    required: true,
  },
  inviteCode: {
    type: String,
    required: true,
  },
})

const leave = () => {
  // Logic for leaving the party
}
</script>
