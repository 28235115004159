<template>
  <div
    class="flex h-screen w-full select-none flex-col overflow-hidden bg-slate-900"
  >
    <div class="overflow-y-auto overflow-x-hidden">
      <NuxtPage />
    </div>
    <KeepAlive>
      <BottomSheet
        ref="bottomSheet"
        @closed="navigationStore.setPartyOpen(false)"
      >
        <PartySection :find-player-button="true" />
      </BottomSheet>
    </KeepAlive>
    <BottomAppBar v-show="bottomBar" />
  </div>
</template>
<script lang="ts" setup>
import BottomAppBar from '~/components/organisms/BottomAppBar.vue'
import { useNavigationStore } from '~/stores/navigation'
import { storeToRefs } from 'pinia'
import PartySection from '~/components/organisms/PartySection.vue'
import BottomSheet from '~/components/organisms/BottomSheet.vue'
import { Haptics, ImpactStyle } from '@capacitor/haptics'

const navigationStore = useNavigationStore()

const { bottomBar, partyOpen } = storeToRefs(navigationStore)

const bottomSheet = ref<InstanceType<typeof BottomSheet>>()

const open = () => bottomSheet.value.open()

const close = () => bottomSheet.value.close()

watch(
  () => partyOpen.value,
  async value => {
    await Haptics.impact({ style: ImpactStyle.Heavy })
    if (value === true) {
      open()
    } else {
      close()
    }
  }
)
</script>
