<template>
  <div class="relative block flex-shrink-0 space-y-2 py-4">
    <MemberItem
      v-for="member in members"
      :id="member.id"
      :avatar="member.avatar"
      :isConnected="true"
      :isLeader="member.isLeader"
      :label="member.title"
      :pseudo="member.pseudo" />
  </div>
</template>
<script lang="ts" setup>
import MemberItem from '~/components/molecules/MemberItem.vue'

const props = defineProps({
  members: {
    type: Object,
    required: true,
  },
})

const members = computed(() => props.members)
</script>
