<template>
  <Teleport to="body">
    <div v-show="opened" class="fixed w-full inset-0 bg-slate-900/60 backdrop-blur-sm" @click="close" />
    <div
      ref="bottomSheet"
      :class="[
        'transition-all duration-300 ease-in-out',
        opened ? 'fixed left-0 top-0 h-full w-full' : 'invisible opacity-0',
        isSwiping ? 'transition-none' : '',
        backgroundClickable && !clickToClose
          ? 'pointer-events-none'
          : 'pointer-events-auto',
      ]"
      @mousedown="clickOnBottomSheet"
      @touchstart="clickOnBottomSheet"
    >
      <div
        v-if="overlay"
        class="invisible fixed bottom-0 left-0 right-0 top-0 bg-slate-900 opacity-0"
      />
      <div
        ref="bottomSheetCard"
        :style="[{ bottom: cardP + 'px' }]"
        :class="[
          'pointer-events-auto fixed left-1/2 mx-0 my-auto w-full -translate-x-1/2 translate-y-0 transform rounded-t-3xl bg-slate-900 pb-12',
          !rounded ? 'rounded-none' : '',
          fullScreen ? 'h-screen' : 'h-auto',
        ]"
      >
        <div ref="pan" class="h-6 border-b border-slate-800 py-3">
          <div
            class="mx-auto my-0 block h-1 w-16 cursor-pointer rounded-full bg-white/30"
          />
        </div>
        <div
          ref="bottomSheetCardContent"
          class="overflow-y-scroll"
          :style="{ height: contentH }"
        >
          <slot />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onMounted, nextTick, ref, reactive } from 'vue'
import { useSwipe } from '@vueuse/core'

const props = defineProps({
  overlay: { type: Boolean, default: true },
  clickToClose: { type: Boolean, default: true },
  rounded: { type: Boolean, default: true },
  swipeable: { type: Boolean, default: true },
  fullScreen: { type: Boolean, default: false },
  backgroundScrollable: { type: Boolean, default: false },
  backgroundClickable: { type: Boolean, default: true }
})

const emit = defineEmits(['closed', 'opened'])

const bottomSheet = ref(null)
const bottomSheetCard = ref(null)
const pan = ref(null)

const opened = ref(false)
const moving = reactive({ value: false })
const isSwiping = ref(false)
const contentH = ref('auto')
const cardP = ref(0)

let cardH = 0
let stripe = 0

onMounted(async () => {
  await nextTick()

  const iPhone = /iPhone/.test(navigator.userAgent) && !(window as any).MSStream
  const aspect = window.screen.width / window.screen.height
  const isIphone = iPhone && aspect.toFixed(3) === '0.462'
  stripe = isIphone ? 20 : 0

  cardH = bottomSheetCard.value.clientHeight
  contentH.value = `${cardH - pan.value.clientHeight}px`
  cardP.value = -cardH - stripe

  if (props.swipeable) {
    useSwipe(pan.value, {
      threshold: 50,
      onSwipeStart() {
        isSwiping.value = true
      },
      onSwipeEnd(e, direction) {
        isSwiping.value = false
        if (direction === 'down') {
          close()
        }
      }
    })
  }
})

const open = () => {
  opened.value = true
  cardP.value = 0
  if (!props.backgroundScrollable) {
    document.documentElement.style.overflowY = 'hidden'
  }
  emit('opened')
}

const close = () => {
  opened.value = false
  cardP.value = -cardH - stripe
  document.documentElement.style.overflowY = ''
  emit('closed')
}

const clickOnBottomSheet = (event: Event) => {
  if (props.clickToClose) {
    const target = event.target as Element
    if (bottomSheetCard.value.contains(target)) {
      return
    }
    close()
  }
}

defineExpose({ open, close })
</script>
