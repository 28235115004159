<template>
  <div class="flex flex-1 rounded-md shadow">
    <div class="relative flex w-full focus-within:z-10">
      <input
        id="invite-code"
        v-model="inviteCode"
        class="block w-full rounded-none rounded-l-md border-0 bg-gray-600 bg-opacity-20 py-2 text-center text-lg text-gray-50 focus:outline-none sm:text-sm sm:leading-6"
        name="invite-code"
        placeholder="Enter invite code"
        type="text"
        @input="handleInput"
      >
    </div>
    <button
      class="relative -ml-px inline-flex items-center rounded-r-md bg-gray-200 bg-opacity-20 px-5 py-2 text-base font-medium text-gray-100 hover:bg-gray-50 hover:bg-opacity-30 hover:text-gray-50"
      type="button"
      @click="joinWithCode(inviteCode)"
    >
      Join
    </button>
  </div>
</template>
<script lang="ts" setup>
import {usePartiesStore} from '~/stores/parties'
import type {Ref} from 'vue'

const {joinWithCode} = usePartiesStore()

const inviteCode: Ref<string> = ref('')
// Handle invitation code input with 3 digits a spacer - and 3 digits again (e.g. 123-456) and only allow numbers and the dash character to be entered
const handleInput = (event: InputEvent) => {
  const input = event.target as HTMLInputElement
  const value = input.value
  const regex = /^[0-9-]*$/
  if (value.length > 7) {
    input.value = value.slice(0, 7)
  } else if (value.length === 4) {
    input.value = value.slice(0, 3) + '-' + value.slice(3)
  } else if (value.length === 8) {
    input.value = value.slice(0, 7)
  } else if (!regex.test(value)) {
    input.value = value.slice(0, -1)
  }
}
</script>
