<template>
  <div class="flex rounded-md shadow">
    <span
      id="invite-code"
      class="h-full w-full rounded-none rounded-l-md border-0 bg-gray-600 bg-opacity-20 py-1.5 text-center text-gray-100 focus:outline-none sm:text-sm sm:leading-6"
      type="text"
      @click="copy(invitationCode)"
    >
      {{ copied ? 'Copied' : invitationCode }}
    </span>
    <button
      class="relative -ml-px inline-flex items-center gap-x-1.5 bg-gray-200 bg-opacity-20 px-3 py-2 text-sm font-semibold text-gray-100 hover:bg-gray-50 hover:bg-opacity-30 hover:text-gray-50"
      type="button"
      @click="copy(invitationCode)"
    >
      <LinkIcon aria-hidden="true" class="-ml-0.5 h-5 w-5" />
    </button>
    <button
      class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md bg-gray-200 bg-opacity-20 px-3 py-2 text-sm font-semibold text-gray-100 hover:bg-gray-50 hover:bg-opacity-30 hover:text-gray-50"
      type="button"
    >
      <ArrowUpOnSquareIcon aria-hidden="true" class="-ml-0.5 h-5 w-5" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import {ArrowUpOnSquareIcon, LinkIcon} from '@heroicons/vue/24/outline'

const  {invitationCode} =  storeToRefs(usePartiesStore())
const {copy, copied} = useClipboard({source: invitationCode})
</script>
