<template>
  <transition
    as="div"
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-out"
    leave-from-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0">
    <div class="flex h-14 items-center rounded-lg">
      <avatar :src="avatar" alt="" />
      <div class="ml-4 flex-1">
        <div class="pr-4">
          <div
            v-if="!isConnected"
            class="h-1 w-full rounded-full bg-gray-700 bg-opacity-30">
            <div
              :style="`width: ${progressPercent}%`"
              class="h-1 rounded-full bg-orange-400 transition-all duration-100 ease-in-out"></div>
          </div>
        </div>

        <div class="flex">
          <p class="truncate font-medium text-white">{{ pseudo }}</p>
          <dot-status v-if="isConnected" status="CONNECTED" />
        </div>
        <player-label v-if="isConnected" :text="label" />
        <player-label v-else :text="`Disconnected...(${progress}s)`" />
      </div>
      <Menu as="div" class="relative inline-block text-left">
        <MenuButton class="flex h-7 w-7 justify-items-center">
          <nuxt-icon
            v-if="isLeader"
            class="text-lg text-gray-400"
            fill
            name="crown" />
          <EllipsisVerticalIcon
            v-else
            class="h-6 w-6 text-center text-gray-400" />
        </MenuButton>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95">
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-slate-800 bg-opacity-80 shadow-lg ring-1 ring-black ring-opacity-5 backdrop-blur focus:outline-none">
            <div class="px-4 py-2 text-gray-100">
              <h1 class="text-base font-bold">Settings</h1>
            </div>
            <div class="flex flex-col space-y-4 px-2 py-2">
              <MenuItem v-slot="{ active }">
                <button
                  class="group flex items-center px-2 text-base text-gray-300 hover:text-gray-50"
                  @click="setLeader(id)">
                  <div
                    class="mr-3 rounded-full p-2 group-hover:bg-slate-700 group-hover:text-gray-300">
                    <ChevronDoubleUpIcon aria-hidden="true" class="h-5 w-5" />
                  </div>
                  Transfer Leadership
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a
                  class="group flex items-center px-2 text-base text-gray-300 hover:text-gray-50"
                  href="#">
                  <div
                    class="mr-3 rounded-full p-2 group-hover:bg-slate-700 group-hover:text-gray-300">
                    <GlobeAltIcon aria-hidden="true" class="h-5 w-5" />
                  </div>
                  View Profile
                </a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a
                  class="group flex items-center px-2 text-base text-gray-300 hover:text-gray-50"
                  href="#">
                  <div
                    class="mr-3 rounded-full p-2 group-hover:bg-slate-700 group-hover:text-gray-300">
                    <ChatBubbleOvalLeftEllipsisIcon
                      aria-hidden="true"
                      class="h-5 w-5" />
                  </div>
                  Send Message
                </a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button
                  class="group flex items-center px-2 text-base text-gray-300 hover:text-gray-50"
                  @click="kickPlayer(id)">
                  <div
                    class="mr-3 rounded-full p-2 group-hover:bg-slate-700 group-hover:text-gray-300">
                    <ArrowRightOnRectangleIcon
                      aria-hidden="true"
                      class="h-5 w-5" />
                  </div>
                  Kick from the party
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </transition>
</template>
<script lang="ts" setup>
import {
  ArrowRightOnRectangleIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChevronDoubleUpIcon,
  EllipsisVerticalIcon,
  GlobeAltIcon,
} from '@heroicons/vue/24/outline'
import DotStatus from '~/components/atoms/DotStatus.vue'
import Avatar from '~/components/atoms/Avatar.vue'
import PlayerLabel from '~/components/atoms/Label.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { usePartiesStore } from '~/stores/parties'

const { setLeader, kickPlayer } = usePartiesStore()

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  isConnected: {
    type: Boolean,
    required: true,
  },
  pseudo: {
    type: String,
    required: true,
  },
  avatar: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  isLeader: {
    type: Boolean,
    default: false,
  },
})

const progress = ref(60)
const progressPercent = ref(100)
const interval = ref()
watchEffect(() => {
  if (!props.isConnected) {
    interval.value = setInterval(() => {
      progress.value -= 1
      // Set the value of the progress bar to the percentage of the progress 0% = 60s and 100% = 0s
      progressPercent.value = (progress.value / 60) * 100
    }, 1000)
  } else {
    clearInterval(interval.value)
    progress.value = 60
  }
})
</script>
